import { PureComponent } from 'react';

import Responsive from 'react-responsive';

class Tablet extends PureComponent {
  render() {
    return (
      <Responsive minWidth={421} maxWidth={1024}>
        {this.props.content}
      </Responsive>
    );
  }
}

export default Tablet;
