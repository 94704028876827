import { PureComponent } from "react";

import Desktop from "constants/responsive/desktop/index";
import { FaCloudUploadAlt } from "react-icons/fa";
import Mobile from "constants/responsive/mobile/index";
import Tablet from "constants/responsive/tablet/index";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  animation: fadeIn 1s;
  padding: 2em;
  width: 100%;
`;
const Container = styled.div`
  border: 3px dashed ${(props) => props.theme.grey2};
  background: ${(props) => props.theme.grey5};
  border-radius: 1em;
  width: 100%;
  transition: ${(props) => props.theme.defaultTransition};
  cursor: pointer;
  padding: 2em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.grey6};
  }
  &:active {
    background: ${(props) => props.theme.white};
    border-color: ${(props) => props.theme.midGreen};
    h2 {
      color: ${(props) => props.theme.midGreen};
    }
    div {
      background: ${(props) => props.theme.midGreen};
    }
  }
`;
const IconContainer = styled.div`
  padding: 1em;
  transition: ${(props) => props.theme.defaultTransition};
  border-radius: 100%;
  background: ${(props) => props.theme.grey2};
  display: flex;
  svg {
    fill: ${(props) => props.theme.white};
  }
  svg:hover {
    fill: ${(props) => props.theme.white};
  }
`;

const H2 = styled.h2`
  transition: ${(props) => props.theme.defaultTransition};
  width: 100%;
  text-align: center;
  user-select: none;
  margin: 1em;
  color: ${(props) => props.theme.grey2};
`;

export default class Uploads extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Container
          onClick={this.props.clickAction ? this.props.clickAction : null}
        >
          <IconContainer>
            <FaCloudUploadAlt size="3em" />
          </IconContainer>
          <Mobile content={<H2>{`Tap to upload ${this.props.text}`}</H2>} />
          <Tablet
            content={<H2>{`Click here to upload ${this.props.text}`}</H2>}
          />
          <Desktop
            content={
              <H2>
                {`Drag & drop or click here to upload ${this.props.text}`}
              </H2>
            }
          />
        </Container>
      </Wrapper>
    );
  }
}
