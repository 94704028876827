import React, { PureComponent } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const Wrapper = styled.div`
  transition: ${(props) => props.theme.fastTransition};
  margin: 1em;
  animation: fadeIn 1s;
  border-radius: 1em;
  overflow: ${(props) => props.showOverflow ? "visible" : "hidden"};
  border: 1px solid ${(props) => props.theme.grey5};
  background: ${(props) => props.theme.white};
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
  align-self: stretch;
  z-index: ${(props) => props.showOverflow ? "auto" : 0};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "auto")};
  flex: auto;
  width: ${(props) => (props.size == "full" ? "100%" : "auto")};
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  position: relative;
  ${media.mobile`
    margin: 0.5em;
  `}
`;

const Group = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${(props) => props.theme.grey2};
  }
  ${media.mobile`
    width: 100%;
    padding: 1em;
  `}
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  background: ${(props) =>
    props.invertColors ? props.theme.white : props.theme.grey5};
  align-items: center;
  justify-content: space-between;
  z-index: ${props => props.showOverflow ? "auto" : 2};
  padding: 1.25em;
  ${media.mobile`
    justify-content: center;
    flex-wrap: wrap;
  `}
`;
const H3 = styled.h3`
  margin: 0em;
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.grey1};
`;
const Container = styled.div`
  text-align: ${(props) => (props.leftAligned ? "left" : "center")};
  transition: ${(props) => props.theme.defaultTransition};
  display: flex;
  justify-content: center;
  padding: 1em;
  flex-wrap: wrap;
  background: ${(props) =>
    props.invertColors ? props.theme.grey5 : "transparent"};
  width: 100%;
  height: ${(props) => (props.fullHeightContainer ? "100%" : "auto")};
  align-items: center;
  box-sizing: border-box;
  p {
    margin: 0.5em;
    color: ${(props) => props.theme.grey1};
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.grey1};
    hyphens: auto;
    line-height: 1.45em;
  }
  &:hover p {
    fill: ${(props) => props.theme.grey0};
  }
  ${media.mobile`
    padding: 0.5em;
  `}
`;

const styles = {
  info: {
    display: "flex",
    paddingLeft: "1em",
    alignItems: "center",
  },
};

class TextBox extends PureComponent {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }

  render() {
    return (
      <Wrapper
        size={this.props.size}
        minWidth={this.props.minWidth}
        minHeight={this.props.minHeight}
        showOverflow={this.props.showOverflow}
      >
        <Header
          invertColors={this.props.invertColors}
          showOverflow={this.props.showOverflow}
        >
          <Group>
            <H3>{this.props.title}</H3>
            {this.props.tooltip ? (
              <Tooltip
                unmountHTMLWhenHide
                html={<TooltipText>{this.props.tooltip}</TooltipText>}
                position="top"
                style={styles.info}
              >
                <FaInfoCircle />
              </Tooltip>
            ) : null}
          </Group>
          {this.props.filters ? this.props.filters : null}
        </Header>
        <Container
          leftAligned={this.props.leftAligned}
          ref={this.componentRef}
          invertColors={this.props.invertColors}
          fullHeightContainer={this.props.fullHeightContainer}
        >
          {this.props.text === null ? "Nothing yet" : this.props.text}
        </Container>
      </Wrapper>
    );
  }
}

export default TextBox;
