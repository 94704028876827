import {
  BottomP,
  Flex,
  Img,
  Padding,
  SkeletonContainer,
} from "constants/styled-components";
import { PureComponent } from "react";
import CollectionGroup from "screens/collections/group/index";
import InfiniteScroll from "react-infinite-scroller";
import SimplePill from "components/simple-pill/index";
import SimpleSkeleton from "components/simple-skeleton/index";
import { adminPlanId, awsPrefix } from "constants/variables";
import { connect } from "react-redux";
import { FaPlus } from "react-icons/fa";
import MainCTA from "components/buttons/main";
import styled, { withTheme } from "styled-components";

const countries = [
  {
    name: "Canada",
    img: "25635ee9-5114-5c34-9f27-a09312d7fdd3",
    id: 5,
  },
  {
    name: "United States",
    img: "7ce02049-3129-5dae-9a78-e27228611ee5",
    id: 390,
  },
  {
    name: "Australia",
    img: "3a1735a8-d822-5482-828e-1bc2031a10a0",
    id: 22,
  },
  {
    name: "South Africa",
    img: "4c12d0e7-4deb-55af-8413-3b80a83db219",
    id: 347,
  },
  {
    name: "United Kingdom",
    img: "aaab44bf-1c8f-50bc-a51c-9ef71361abc6",
    id: 377,
  },
  {
    name: "Mexico",
    img: "88a352a3-f63d-5e6c-873f-cdf11bdbfc9c",
    id: 283,
  },
  {
    name: "Spain",
    img: "d85c1a5d-33cf-559c-8340-ac4089868694",
    id: 351,
  },
  {
    name: "Ecuador",
    img: "0ce88dba-dee0-57b5-8601-082b53026e04",
    id: 104,
  },
];

const styles = {
  main: {
    paddingTop: "2em",
    flexWrap: "wrap",
  },
};

const Spacing = styled.div`
  @media (min-width: 1408px) {
    width: 18em;
  };
`;

const PageTitle = styled.h3`
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 0.5em;
  animation: fadeIn 0.5s;
  
  @media (min-width: 1408px) {
    width: auto
  }
`;

class PublicCollections extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      showSettings: false,
    };
    this.loadMore = this.loadMore.bind(this);
    this.handleCreatePipelineCollection = this.handleCreatePipelineCollection.bind(this);
  }

  componentDidMount() {
    this.loadFunc();
  }

  loadFunc() {
    const currentPageState = this.state.currentPage;
    this.props.apiCall({
      method: "GET",
      component: `COLLECTIONS_GET_${this.props.slug.toUpperCase()}`,
      route: `collection/${this.props.slug}`,
      params: {
        page: currentPageState,
      },
    });
  }

  loadMore() {
    const currentPageState = this.state.currentPage;
    this.setState({ currentPage: currentPageState + 1 }, this.loadFunc);
  }

  handlePipelineCountry(data) {
    this.props.setData({
      type: "RETAIL_PIPELINES_SELECT_COUNTRY",
      data: data,
    });
  }

  handleCreatePipelineCollection() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "CREATE_PIPELINE_COLLECTION"
    })
  }

  render() {
    const data =
      this.props.slug === "retail_pipelines"
        ? this.props.dataCollectionsPublic[this.props.slug]?.data?.filter(
            (x) =>
              x.country_id === this.props.dataCollectionsPublicSettings.country
          )
        : this.props.dataCollectionsPublic[this.props.slug]?.data;
    if (this.props.dataCollectionsPublic[this.props.slug]) {
      return (
        <>
          {this.props.slug === "retail_pipelines" ? (
            <>
              <Flex style={{ ...styles.main, padding: "2em 6em 0 6em" }}>
                {this.props.isAdmin && (
                  <Spacing/>
                )}
                <PageTitle>{this.props.title || "All Retail Pipelines"}</PageTitle>
                {this.props.isAdmin && (
                  <MainCTA
                      noMargin
                      text="Create New Pipeline Collection"
                      background={this.props.theme.midGreen}
                      backgroundHover={this.props.theme.deepGreen}
                      icon={<FaPlus className="override-fill" fill="white"/>}
                      clickAction={this.handleCreatePipelineCollection}
                  />
                )}
              </Flex>
              <Flex style={styles.main}>
                {countries.map((x, i) => (
                    <SimplePill
                      key={`country-filter-${i}-${x.d}`}
                      clickAction={this.handlePipelineCountry.bind(this, x.id)}
                      highlighted={
                        x.id ===
                        this.props.dataCollectionsPublicSettings.country
                      }
                      primary_color="(255,255,255)"
                      name={x.name}
                      icon={
                        <Img
                          draggable="false"
                          src={`${awsPrefix}countries/${x.img}`}
                        />
                      }
                    />
                  ))}
              </Flex>
            </>
          ) : null}
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore}
            hasMore={
              this.props.dataCollectionsPublic[this.props.slug].more &&
              this.props.dataCollectionsPublic[this.props.slug].page ==
                this.state.currentPage
            }
            initialLoad={false}
            useWindow={false}
          >
            <Padding amount="0 3em">
              <CollectionGroup
                fallbackText="Contact us to access nationwide store checks in this country"
                data={data}
              />
            </Padding>
          </InfiniteScroll>
          {this.props.noMore &&
          !this.props.dataCollectionsPublic[this.props.slug].more &&
          data &&
          data.length > 5 ? (
            <BottomP>{`- No more ${this.props.noMore} -`}</BottomP>
          ) : null}
        </>
      );
    }
    return (
      <SkeletonContainer>
        {[...Array(18).keys()].map((x, i) => (
          <SimpleSkeleton
            key={`pub-skel-${i}-${x}`}
            width="15vw"
            height="15vw"
          />
        ))}
      </SkeletonContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  isAdmin: state.dataUser?.plan_id === adminPlanId,
  dataCollectionsPublic: state.dataCollectionsPublic,
  dataCollectionsPublicSettings: state.dataCollectionsPublicSettings,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(PublicCollections));
