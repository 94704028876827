import {
  Container,
  DropdownOptions,
  LandingPageTitle,
  Name,
  Group,
  Mask,
} from "constants/styled-components/index";
import {
  FaCheckDouble,
  FaInfo,
  FaShareAlt,
  FaChartLine,
  FaAngleDown,
  FaFilter,
  FaCropAlt,
  FaCheck,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import { PureComponent } from "react";
import { awsPrefix, sortOptions, emptyArray } from "constants/variables/index";

import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import LandingPageMenu from "components/landing-page-menu/index";
import Loader from "components/loader/index";
import MultipleSelectMenu from "components/multiple-select-menu/index";
import ExcelExportButton from "components/buttons/download-excel/index";
import PlanogramFilters from "./planogram-filters/index";
import StarButton from "components/buttons/star/index";
import ToggleTwoOptions from "components/toggle-two-options/index";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import styled from "styled-components";
import { textColor, LoadingComponent } from "constants/functions/index";
import Loadable from "react-loadable";

const ReportsToggle = Loadable({
  loader: async () =>
    import(
      /* webpackChunkName: "dynamic-ReportsToggle" */ "components/reports-toggle/index"
    ),
  loading: LoadingComponent,
  delay: 300,
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.mobile`
    justify-content: center;
    align-items: center;
    svg {
      margin-top: 0.5em;
    }
  `};
`;

const styles = {
  flexEnd: { justifyContent: "flex-end" },
  flexStart: { justifyContent: "flex-start" },
};

class PlanogramSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.showScatter = this.showScatter.bind(this);
    this.showVisuals = this.showVisuals.bind(this);
    this.handlePPT = this.handlePPT.bind(this);
    this.handleExcel = this.handleExcel.bind(this);
    this.getAnalyticsHelp = this.getAnalyticsHelp.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.cancelMultipleSelect = this.cancelMultipleSelect.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.filterRetailers = this.filterRetailers.bind(this);
    this.showMoreOptions = this.showMoreOptions.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.openPlanogramSortSettings = this.openPlanogramSortSettings.bind(this);
    this.onShare = this.onShare.bind(this);
    this.showCoverageModel = this.showCoverageModel.bind(this);
  }
  handleSelectAll() {
    const currentSelection =
      this.props.feedMultiplePlanogramSelect.data || emptyArray;
    const all = currentSelection.concat(
      this.props.dataLandingPageRVI &&
        this.props.dataLandingPageRVI.planogram_checks &&
        this.props.dataLandingPageRVI.planogram_checks.data
    );
    const uniqueArray = [...new Set(all.map((x) => x.id))];

    this.props.setData({
      type: "SELECT_MULTIPLE_PLANOGRAMS_ALL",
      data: uniqueArray
        .map((x) => all.filter((y) => x === y.id)[0])
        .slice(0, 100),
    });
  }
  zoomIn() {
    this.props.setData({ type: "LOCATIONS_MAP_ZOOM_IN" });
  }
  zoomOut() {
    this.props.setData({ type: "LOCATIONS_MAP_ZOOM_OUT" });
  }
  filterRetailers() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "FILTER_MAP_BY_RETAILER",
    });
  }
  showVisuals() {
    this.props.setData({
      type: "FACINGS_SHOW_VISUALS",
    });
  }
  showScatter() {
    this.props.setData({
      type: "FACINGS_SHOW_SCATTER_PLOT",
    });
  }
  handlePPT() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "ANALYTICS_EXPORT_POWERPOINT",
    });
  }
  showCoverageModel() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "COLLECTION_SHOW_COVERAGE_MODEL",
    });
  }
  handleExcel() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "ANALYTICS_EXPORT_EXCEL",
    });
  }
  showMoreOptions() {
    this.props.setData({
      type: this.props.showPlanogramMoreMenu
        ? "HIDE_PLANOGRAM_MORE_MENU"
        : "SHOW_PLANOGRAM_MORE_MENU",
    });
  }
  toggleSelect() {
    if (this.props.showFeedShiftMultipleSelect) {
      this.props.setData({
        type: "HIDE_FEED_SHIFT_MULTIPLE_SELECT",
      });
    } else {
      this.props.setData({
        type: "SHOW_FEED_SHIFT_MULTIPLE_SELECT",
      });
    }
  }
  openPlanogramSortSettings() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "PLANOGRAM_SHOW_SORT_OPTIONS",
    });
  }
  getAnalyticsHelp() {
    window
      .open(`${awsPrefix}web-app/Shelfgram+-+RVI+Explanation.pdf`, "_blank")
      .focus();
  }
  cancelMultipleSelect() {
    this.props.setData({
      type: "HIDE_FEED_SHIFT_MULTIPLE_SELECT",
    });
  }
  onShare() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "LINK_TO_PAGE",
    });
  }
  render() {
    const noAccess =
      this.props.dataUser.plan_id === 1 ||
      (this.props.dataLandingPageAbout &&
        this.props.dataLandingPageAbout.access_check &&
        !this.props.dataLandingPageAbout.access);
    return (
      <Wrapper id="group-3">
        <Group style={styles.flexStart}>
          <LandingPageMenu
            primaryColor={this.props.primaryColor}
            menuPages={this.props.menuPages}
            name={this.props.name}
            menuType={this.props.menuType}
            activeTab={this.props.tab}
            type={this.props.type}
            key="landing-page-menu-planogram"
            img={this.props.img}
          />
          {this.props.isAnalytics &&
            this.props.subTab &&
            this.props.subTab2 && (
              <ReportsToggle
                urlPrefix={this.props.menuType}
                subTab={this.props.subTab}
                subTab2={this.props.subTab2}
              />
            )}
          {this.props.isAnalytics && <StarButton />}
          {!noAccess && this.props.isPlanogram ? <PlanogramFilters /> : null}
          {!noAccess &&
          this.props.isLocations &&
          this.props.page === "collection" ? (
            <Container onClick={this.filterRetailers}>
              <FaFilter />
              {this.props.dataLandingPageAbout &&
              this.props.dataLandingPageAbout.map_filters &&
              this.props.dataLandingPageAbout.map_filters.length > 0
                ? this.props.dataLandingPageAbout.map_filters.length === 1
                  ? this.props.dataLandingPageAbout.map_filters[0]
                  : `${this.props.dataLandingPageAbout.map_filters.length} retailers`
                : "All retailers"}
            </Container>
          ) : null}
        </Group>
        {!noAccess &&
        this.props.isPricing &&
        this.props.dataLandingPageAbout &&
        this.props.dataLandingPageAbout.show_pricing &&
        this.props.dataLandingPageAbout.show_facings ? (
          <ToggleTwoOptions
            inMenu
            option1="ALL OTHER"
            option2="CLUB"
            offActionConstant="USER_SET_PREFERENCE_PRICING_MASS"
            onActionConstant="USER_SET_PREFERENCE_PRICING_CLUB"
            textColor={
              this.props.primaryColor
                ? textColor(this.props.primaryColor)
                : this.props.dataLandingPageAbout.primary_color &&
                  textColor(this.props.dataLandingPageAbout.primary_color)
            }
            toggledOn={!this.props.dataUser.pricing_preference_mass}
          />
        ) : null}
        {this.props.isPlanogram &&
        this.props.feedMultiplePlanogramSelect.data &&
        this.props.feedMultiplePlanogramSelect.data.length > 0 ? (
          <MultipleSelectMenu
            primaryColor={this.props.primaryColor}
            isPlanogram
            data={this.props.feedMultiplePlanogramSelect.data}
          />
        ) : null}
        {noAccess ? null : this.props.isLocations &&
          this.props.dataLandingPageLocations &&
          this.props.dataLandingPageLocations !== "404" &&
          this.props.dataLandingPageLocations.length > 0 ? (
          <Group style={styles.flexEnd}>
            <Container onClick={this.zoomIn}>
              <FaPlus />
              Zoom in
            </Container>
            <Container onClick={this.zoomOut}>
              <FaMinus />
              Zoom out
            </Container>
            {this.props.dataLandingPageAnalytics &&
              this.props.dataLandingPageAnalytics
                .last_updated_coverage_model && (
                <DesktopOrTablet
                  content={
                    <Container onClick={this.showCoverageModel}>
                      <FaInfo />
                      Sampling methodology
                    </Container>
                  }
                />
              )}
          </Group>
        ) : this.props.isPricing ? (
          this.props.dataLandingPageAbout &&
          this.props.dataLandingPageAbout.pricing &&
          (!this.props.dataUser.pricing_preference_mass ||
            !this.props.dataLandingPageAbout.facings) ? (
            <ExcelExportButton
              data={this.props.dataLandingPageAbout.pricing.data}
            />
          ) : this.props.dataLandingPageAbout &&
            this.props.dataLandingPageAbout.facings &&
            this.props.dataLandingPageAbout.facings.data ? (
            <Group style={styles.flexEnd}>
              {this.props.dataLandingPageAnalytics &&
              this.props.dataLandingPageAnalytics.facings_show_scatter ? (
                <Container onClick={this.showVisuals}>
                  <FaCropAlt />
                  Show Visuals
                </Container>
              ) : (
                <Container onClick={this.showScatter}>
                  <FaChartLine />
                  Show Scatter
                </Container>
              )}
              <ExcelExportButton
                facings
                data={this.props.dataLandingPageAbout.facings.data}
              />
            </Group>
          ) : (
            <Loader width="auto" color="white" size="1.5em" />
          )
        ) : this.props.isPlanogram ? (
          <DesktopOrTablet
            content={
              <Group id="group-6" style={styles.flexEnd}>
                {this.props.showFeedShiftMultipleSelect ? (
                  <Container onClick={this.cancelMultipleSelect}>
                    {this.props.translations["55"] || "Done"}
                  </Container>
                ) : (
                  <Container
                    onClick={this.showMoreOptions}
                    active={this.props.showPlanogramMoreMenu}
                    svgMarginRight="0"
                  >
                    <LandingPageTitle>Actions</LandingPageTitle>
                    <FaAngleDown className="FaAngleDown" />
                    {this.props.showPlanogramMoreMenu ? (
                      <DropdownOptions
                        right="0"
                        minWidth="22em"
                        primaryColor={this.props.primaryColor}
                      >
                        <Mask>
                          <Container
                            wide
                            onClick={this.toggleSelect}
                            active={this.props.showFeedShiftMultipleSelect}
                          >
                            <FaCheck />
                            <Name>
                              {this.props.translations["16"] || "Select"}
                            </Name>
                          </Container>
                          {this.props.dataLandingPageRVI &&
                          this.props.dataLandingPageRVI.planogram_checks &&
                          this.props.dataLandingPageRVI.planogram_checks.data &&
                          this.props.dataLandingPageRVI.planogram_checks.data
                            .length > 0 ? (
                            <Container wide onClick={this.handleSelectAll}>
                              <FaCheckDouble size="1em" />
                              <Name>Select all</Name>
                            </Container>
                          ) : null}
                          {this.props.menuPages &&
                            this.props.menuPages.length > 0 && (
                              <Container wide onClick={this.onShare}>
                                <FaShareAlt />
                                <Name>Share</Name>
                              </Container>
                            )}
                          {sortOptions[
                            this.props.dataLandingPageRVISettings
                              .planogram_checks.sort
                          ] ? (
                            <Container
                              wide
                              onClick={this.openPlanogramSortSettings}
                            >
                              {
                                sortOptions[
                                  this.props.dataLandingPageRVISettings
                                    .planogram_checks.sort
                                ].icon
                              }
                              <Name>
                                {
                                  sortOptions[
                                    this.props.dataLandingPageRVISettings
                                      .planogram_checks.sort
                                  ].displayName
                                }
                              </Name>
                            </Container>
                          ) : null}
                        </Mask>
                      </DropdownOptions>
                    ) : null}
                  </Container>
                )}
              </Group>
            }
          />
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  dataLandingPageAbout: state.dataLandingPageAbout,
  dataLandingPageAnalytics: state.dataLandingPageAnalytics,
  dataLandingPageLocations: state.dataLandingPageLocations,
  dataLandingPageRVI: state.dataLandingPageRVI,
  dataLandingPageRVISettings: state.dataLandingPageRVISettings,
  showPlanogramMoreMenu: state.showPlanogramMoreMenu,
  showFeedShiftMultipleSelect: state.showFeedShiftMultipleSelect,
  feedMultiplePlanogramSelect: state.feedMultiplePlanogramSelect,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramSettings);
