import { PureComponent } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import styled, { withTheme } from "styled-components";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import { tooltipDelay } from "constants/variables/index";
import { history } from "@redux/store";

export const Container = styled.div`
  border-radius: 100%;
  height: 3.5em;
  width: 3.5em;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  transition: ${(props) => props.theme.defaultTransition};
  align-items: center;
  margin: 0.5em;
  text-transform: uppercase;
  background: ${(props) =>
    props.primaryColor
      ? props.primaryColor
      : props.active
      ? props.theme.grey6
      : "rgba(0,0,0,0.45)"};
  box-shadow: ${(props) => props.theme.notSubtleBoxShadow};
  svg {
    transition: ${(props) => props.theme.defaultTransition};
    min-width: 1.5em;
    min-height: 1.5em;
    margin-right: 0.25em;
    margin-left: 0.25em;
    fill: ${(props) => (props.active ? "rgba(0,0,0,0.6)" : "#FFFFFF")};
  }
  &:hover {
    box-shadow: ${(props) => props.theme.notSubtleBoxShadow};
    background: ${(props) =>
      props.disabled
        ? props.primaryColor
        : props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.grey6};
    svg {
      fill: ${(props) =>
        props.active || props.disabled ? "auto" : props.theme.grey1};
    }
  }
  &:active {
    transform: scale(0.99);
    background: ${(props) => (props.disabled ? props.primaryColor : "white")};
    svg {
      min-width: ${(props) => (props.disabled ? "1.5em" : "2em")};
      min-height: ${(props) => (props.disabled ? "1.5em" : "2em")};
    }
  }
  ${media.mobile`
    max-height: 4em;
    align-self: stretch;
    margin: 0 0.5em;
    svg {
      margin-bottom: 0;
      margin-top: 0 !important;
    }
  `};
`;

class StarButton extends PureComponent {
  constructor(props) {
    super(props);
    this.starOn = this.starOn.bind(this);
    this.starOff = this.starOff.bind(this);
  }

  starOn() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "STAR_A_VIEW",
    });
  }
  starOff() {
    const pathname = this.props.router.location.pathname;
    this.props.setData({
      type: "STAR_BUTTON_CHECK_OFF",
      data: `${pathname}${this.props.router.location.search}`,
    });
    this.props.apiCall({
      method: "POST",
      component: "STAR_CHECK_OFF_SAVE",
      route: "starred/off",
      body: {
        user_id: this.props.dataUser.id,
        pathname: `${pathname}${this.props.router.location.search}`,
      },
    });
    let url = new URL(window.location.href);
    let params = url.searchParams;
    params.delete("starred");
    history.replace({
      pathname: pathname,
      search: params.toString(),
      state: {},
    });
  }
  render() {
    const active =
      this.props.dataUser &&
      this.props.dataUser.starred &&
      this.props.dataUser.starred
        .map((x) => x.pathname)
        .includes(
          `${this.props.router.location.pathname}${this.props.router.location.search}`
        );
    const disabled =
      this.props.dataUser &&
      this.props.dataUser.starred &&
      this.props.dataUser.starred.length > 49;
    return (
      <Tooltip
        unmountHTMLWhenHide
        html={
          <TooltipText>
            {!active && disabled
              ? "You can only save 50 starred views"
              : `Click to ${active ? "unstar" : "star"} this view`}
          </TooltipText>
        }
        width="auto"
        delay={tooltipDelay}
        position={this.props.position}
      >
        <Container
          circle
          disabled={disabled && !active}
          active={active}
          onClick={active ? this.starOff : disabled ? null : this.starOn}
          primaryColor={active && this.props.theme.midYellow}
          hoverBackgroundColor={active && this.props.theme.deepYellow}
        >
          {active ? <FaStar /> : <FaRegStar />}
        </Container>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(StarButton)
);
