import { PureComponent } from "react";

import { connect } from "react-redux";
import styled from "styled-components";

const Container = styled.span`
  color: ${(props) => props.theme.grey2};
  padding: 0.75em 1em;
  cursor: pointer;
  border-radius: 2em;
  transition: ${(props) => props.theme.fastTransition};
  font-weight: bold;
  margin: 0.25em;
  background: ${(props) => props.theme.grey5};
  &:hover {
    background: ${(props) => props.theme.grey6};
    color: ${(props) => props.theme.grey1};
  }
  &:active {
    transform: scale(0.97);
  }
`;

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "LOGIN",
      extraData: {
        redirect: this.props.router.location.pathname,
        signUp: false,
      },
    });
  }

  render() {
    return <Container onClick={this.handleClick}>Login</Container>;
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
