var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from "react";
import Tooltip from "components/tooltip";
import TextBox from "components/text-box";
import Selector from "components/selector";
import { useAppSelector } from "@redux/hooks";
import { emptyArray } from "constants/variables";
import { FaQuestionCircle } from "react-icons/fa";
import { getImageSource } from "shared/helpers/image";
import { questionCodeMetadataRows } from "../QuestionCode.config";
import { FullWidth, Option, TooltipText } from "constants/styled-components";
import { selectQuestionCodeMetadata } from "@redux/selectors/admin/questionCodeSelectors";
import { StyledQuestionCode as Styled } from "components/question-code/QuestionCode.styled";
var OPTIONS = {
    'photo_sources': [],
    'data_products': []
};
export var MetadataSelectors = function (_a) {
    var localQuestionCode = _a.localQuestionCode, handleChange = _a.handleChange;
    var metadata = useAppSelector(selectQuestionCodeMetadata);
    var selectorOptions = useMemo(function () {
        return questionCodeMetadataRows.reduce(function (options, _a) {
            var _b;
            var source = _a.source;
            options[source] = (_b = Object.entries(metadata[source]).map(function (_a) {
                var id = _a[0], item = _a[1];
                return ({
                    type: source,
                    value: item.name,
                    img: item.img || null,
                    id: parseInt(id),
                    label: (React.createElement(Option, null,
                        item.img && (React.createElement("img", { src: getImageSource(__assign(__assign({}, item), { source: "partners" })), alt: item.name })),
                        item.name))
                });
            })) !== null && _b !== void 0 ? _b : emptyArray;
            return options;
        }, OPTIONS);
    }, [metadata]);
    return (React.createElement(TextBox, { showOverflow: true, title: "Source Data", minWidth: "100%", text: questionCodeMetadataRows.map(function (_a) {
            var title = _a.title, source = _a.source, field = _a.field;
            return (React.createElement(Styled.MetadataSelectors, { key: source },
                React.createElement(FullWidth, { display: "flex" },
                    React.createElement(Styled.MetadataType, null, title),
                    source === "data_products" && (React.createElement(Tooltip, { width: "auto", position: "top", unmountHTMLWhenHide: true, html: React.createElement(TooltipText, { maxWidth: "600px" },
                            React.createElement(Styled.TooltipDescription, null, "Describes who has access to data from this question code"),
                            React.createElement(Styled.SummaryList, null, Object.values(metadata[source]).map(function (_a) {
                                var name = _a.name, summary = _a.summary;
                                return (React.createElement("li", { key: name },
                                    React.createElement("strong", null, name),
                                    ": ",
                                    summary));
                            }))) },
                        React.createElement(FaQuestionCircle, { className: "override-fill" })))),
                React.createElement(Selector, { portal: true, isSingle: true, minWidth: "100%", hideSelectorType: true, options: selectorOptions[source], placeholder: "Select ".concat(title.toLowerCase(), "..."), clickAction: function (e) { return handleChange(field, e.data.id); }, defaultValue: selectorOptions[source].find(function (option) { return option.id === localQuestionCode[field]; }) })));
        }) }));
};
