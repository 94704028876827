import {
  Container,
  DropdownOptions,
  Group,
  LandingPageTitle,
  Name,
  Mask,
} from "constants/styled-components/index";
import {
  FaPlayCircle,
  FaCheckDouble,
  FaFingerprint,
  FaShareAlt,
  FaFilter,
  FaGripHorizontal,
  FaTimesCircle,
} from "react-icons/fa";
import { PureComponent } from "react";
import { emptyArray, sortOptions } from "constants/variables/index";
import { queryStringToObject } from "constants/functions/index";
import { FaAngleDown } from "react-icons/fa";
import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import UpgradeIcon from "components/upgrade-icon/index";
import { Link } from "react-router-dom";
import NumberIndicator from "components/number-indicator/index";
import StarButton from "components/buttons/star/index";
import LandingPageMenu from "components/landing-page-menu/index";
import Loader from "components/loader/index";
import Mobile from "constants/responsive/mobile/index";
import MultipleSelectMenu from "components/multiple-select-menu/index";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const closeWhitelist = [
  "page-menu",
  "group-1",
  "group-2",
  "group-3",
  "group-4",
  "group-5",
  "group-6",
];

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.mobile`
    justify-content: center;
    align-items: center;
    svg {
      margin-top: 0.5em;
    }
  `};
`;

const styles = {
  flexEnd: { justifyContent: "flex-end" },
  flexStart: { justifyContent: "flex-start" },
  filterContainer: {
    minWidth: "8em",
  },
};

class FeedSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.openFeedLayoutSettings = this.openFeedLayoutSettings.bind(this);
    this.openFeedSortSettings = this.openFeedSortSettings.bind(this);
    this.handleCloseAll = this.handleCloseAll.bind(this);
    this.onShare = this.onShare.bind(this);
    this.handleFeedFiltersOpen = this.handleFeedFiltersOpen.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.showMoreOptions = this.showMoreOptions.bind(this);
    this.onSlideshow = this.onSlideshow.bind(this);
    this.generateWordCloud = this.generateWordCloud.bind(this);
  }
  onSlideshow() {
    this.props.setData({
      type: "SHOW_FULLSCREEN_SLIDESHOW",
    });
  }
  openFeedLayoutSettings() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: this.props.isFlyer
        ? "FLYER_LAYOUT_SETTINGS"
        : "FEED_LAYOUT_SETTINGS",
    });
  }
  generateWordCloud() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "WORD_CLOUD",
    });
  }
  onShare() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "LINK_TO_PAGE",
    });
  }
  openFeedSortSettings() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: this.props.isFlyer ? "FLYER_SORT_SETTINGS" : "FEED_SORT_SETTINGS",
    });
  }
  closeAllMenus() {
    this.props.setData({ type: "HIDE_FEED_DROPDOWN_MENUS" });
  }
  handleCloseAll(e) {
    if (e && e.target && closeWhitelist.includes(e.target.id)) {
      this.props.setData({
        type: "HIDE_FEED_DROPDOWN_MENUS",
      });
    }
  }
  handleFeedFiltersOpen() {
    this.handleCloseAll();
    this.props.setData({
      type: "SHOW_MODAL",
      data: this.props.isFlyer ? "FLYER_FILTERS" : "FEED_FILTERS",
      extraData: "LANDING_PAGE",
    });
  }
  handleSelectAll() {
    const currentSelection =
      this.props.feedMultiplePhotoSelect.data || emptyArray;
    let newSelection = [];
    if (
      this.props.dataFeed &&
      this.props.dataFeed.data &&
      currentSelection.length === this.props.dataFeed.data.length
    ) {
      newSelection = [];
    } else if (currentSelection.length === 0) {
      newSelection =
        (this.props.dataFeed && this.props.dataFeed.data) || emptyArray;
    } else if (
      currentSelection.length > 0 &&
      this.props.dataFeed &&
      this.props.dataFeed.data
    ) {
      const allPhotos = currentSelection.concat(this.props.dataFeed.data);
      const uniqueArray = [...new Set(allPhotos.map((x) => x.id))];
      newSelection = uniqueArray.map(
        (x) => allPhotos.filter((y) => x === y.id)[0]
      );
    }
    this.props.setData({
      type: "SELECT_MULTIPLE_PHOTOS_ALL",
      data: newSelection,
    });
  }
  showMoreOptions() {
    this.props.setData({
      type: this.props.showFeedMoreMenu
        ? "HIDE_FEED_DROPDOWN_MENUS"
        : "SHOW_FEED_MORE_MENU",
    });
  }
  renderNumPhotoFilters() {
    const filtersToConsider =
      this.props.feedFilterRules.existing &&
      Array.isArray(this.props.feedFilterRules.existing.filter)
        ? this.props.feedFilterRules.existing.filter.reduce((acc, x) => {
            if (x && x.length > 0) {
              return acc.concat(x);
            }
            return acc;
          }, [])
        : [];
    return filtersToConsider.length;
  }
  renderNumFlyerFilters() {
    let numFilters = 0;
    if (this.props.dataFlyersSettings.existing.front_page_only) {
      numFilters += 1;
    }
    if (
      this.props.dataFlyersSettings.existing.text_selected &&
      this.props.dataFlyersSettings.existing.text_selected.length > 1
    ) {
      numFilters += 1;
    }
    if (
      this.props.dataFlyersSettings.existing.products_selected &&
      this.props.dataFlyersSettings.existing.products_selected.length > 0
    ) {
      numFilters += 1;
    }
    if (
      this.props.dataFlyersSettings.existing.retailers_selected &&
      this.props.dataFlyersSettings.existing.retailers_selected.length > 0
    ) {
      numFilters += 1;
    }
    if (
      this.props.dataFlyersSettings.existing.brands_selected &&
      this.props.dataFlyersSettings.existing.brands_selected.length > 0
    ) {
      numFilters += 1;
    }
    if (
      this.props.dataFlyersSettings.existing.categories_selected &&
      this.props.dataFlyersSettings.existing.categories_selected.length > 0
    ) {
      numFilters += 1;
    }
    if (this.props.dataFlyersSettings.existing.dates_selected) {
      numFilters += 1;
    }
    return numFilters;
  }

  render() {
    const numFilters = this.props.isFlyer
      ? this.renderNumFlyerFilters()
      : this.renderNumPhotoFilters();
    const multipleSelectData = this.props.isFlyer
      ? this.props.feedMultipleFlyerSelect.data
      : this.props.feedMultiplePhotoSelect.data;
    return (
      <Wrapper id="page-menu" onClick={this.handleCloseAll}>
        <Group id="group-4" style={styles.flexStart}>
          {this.props.showHeader ||
          (this.props.type !== "landing" &&
            this.props.type !== "saved-collection" &&
            this.props.type !== "uploaded-collection") ? null : (
            <LandingPageMenu
              primaryColor={this.props.primaryColor}
              menuPages={this.props.menuPages}
              type={this.props.type}
              name={this.props.name}
              hideDropdown={
                this.props.page === "text" || this.props.page === "related"
              }
              activeTab={this.props.activeTab || "feed"}
              key="landing-page-menu-feed"
              page={this.props.page}
              menuType={this.props.menuType}
              img={this.props.img}
            />
          )}
          <Container
            style={styles.filterContainer}
            onClick={this.handleFeedFiltersOpen}
            className="menuOptionSpacer"
          >
            <FaFilter size="1em" />
            {this.props.translations["15"] || "Filters"}
            {numFilters > 0 && <NumberIndicator number={numFilters} />}
          </Container>
          {numFilters > 0 && <StarButton position="right" />}
        </Group>
        {multipleSelectData && multipleSelectData.length > 0 ? (
          <MultipleSelectMenu
            isFlyer={this.props.isFlyer}
            primaryColor={this.props.primaryColor}
            initialDate={
              this.props.isFlyer
                ? null
                : this.props.feedMultiplePhotoSelect.date
            }
            data={multipleSelectData}
          />
        ) : null}
        <Mobile
          content={
            <Container
              onClick={this.showMoreOptions}
              active={this.props.showFeedMoreMenu}
              mobileAlign="center"
            >
              <Name>Actions</Name>
              {this.props.showFeedMoreMenu ? (
                <DropdownOptions
                  right="0"
                  minWidth="16em"
                  primaryColor={this.props.primaryColor}
                >
                  <Mask>
                    {sortOptions[
                      this.props.feedFilterRules.new.sort ||
                        (this.props.dataUser &&
                          this.props.dataUser.sort_preference)
                    ] ? (
                      <Container wide onClick={this.openFeedSortSettings}>
                        {
                          sortOptions[
                            queryStringToObject(
                              this.props.router.location.search
                            ).sort ||
                              this.props.feedFilterRules.new.sort ||
                              (this.props.dataUser &&
                                this.props.dataUser.sort_preference) ||
                              "relevant"
                          ].icon
                        }
                        <Name>
                          {
                            sortOptions[
                              queryStringToObject(
                                this.props.router.location.search
                              ).sort ||
                                this.props.feedFilterRules.new.sort ||
                                (this.props.dataUser &&
                                  this.props.dataUser.sort_preference) ||
                                "relevant"
                            ].displayName
                          }
                        </Name>
                      </Container>
                    ) : null}
                    <Container wide onClick={this.onShare}>
                      <FaShareAlt />
                      <Name>Share</Name>
                    </Container>
                  </Mask>
                </DropdownOptions>
              ) : null}
            </Container>
          }
        />
        {!this.props.isFlyer &&
        this.props.dataFeed &&
        this.props.dataFeed.data &&
        this.props.dataFeed.data.length === 0 ? null : (
          <DesktopOrTablet
            content={
              <Group id="group-6" style={styles.flexEnd}>
                {
                  <Container
                    onClick={this.showMoreOptions}
                    active={this.props.showFeedMoreMenu}
                    svgMarginRight="0"
                  >
                    <LandingPageTitle>Actions</LandingPageTitle>
                    <FaAngleDown className="FaAngleDown" />
                    {this.props.showFeedMoreMenu ? (
                      <DropdownOptions
                        right="0"
                        minWidth="22em"
                        primaryColor={this.props.primaryColor}
                      >
                        <Mask>
                          {this.props.isFlyer ? null : this.props.dataFeed &&
                            this.props.dataFeed.data &&
                            this.props.dataFeed.data.length > 0 ? (
                            <Container wide onClick={this.handleSelectAll}>
                              {this.props.dataFeed &&
                              this.props.dataFeed.data &&
                              this.props.feedMultiplePhotoSelect &&
                              this.props.feedMultiplePhotoSelect.data &&
                              this.props.feedMultiplePhotoSelect.data.length ==
                                this.props.dataFeed.data.length ? (
                                <>
                                  <FaTimesCircle size="1em" />
                                  <Name>Deselect all</Name>
                                </>
                              ) : (
                                <>
                                  <FaCheckDouble size="1em" />
                                  <Name>Select all</Name>
                                </>
                              )}
                            </Container>
                          ) : (
                            <Loader
                              color="white"
                              width="9em"
                              size="1.5em"
                              padding="0"
                            />
                          )}
                          <Container wide onClick={this.onShare}>
                            <FaShareAlt />
                            <Name>Share</Name>
                          </Container>
                          {this.props.dataFeed ? (
                            <Container wide onClick={this.onSlideshow}>
                              <FaPlayCircle />
                              <Name>Slideshow</Name>
                            </Container>
                          ) : null}
                          {this.props.dataUser &&
                          this.props.dataUser.plan_id === 1 ? (
                            <Link
                              to="/enterprise/analytics_features"
                              style={{ width: "100%" }}
                            >
                              <Container>
                                <UpgradeIcon margin="0 0.75em 0 0" />
                                <Name>Generate word cloud</Name>
                              </Container>
                            </Link>
                          ) : (
                            <Container wide onClick={this.generateWordCloud}>
                              <FaFingerprint />
                              <Name>Generate word cloud</Name>
                            </Container>
                          )}
                          {sortOptions[
                            this.props.feedFilterRules.new.sort ||
                              this.props.dataUser.sort_preference
                          ] ? (
                            <Container wide onClick={this.openFeedSortSettings}>
                              {this.props.isFlyer
                                ? sortOptions[
                                    this.props.dataFlyersSettings.sort
                                  ].icon
                                : sortOptions[
                                    queryStringToObject(
                                      this.props.router.location.search
                                    ).sort ||
                                      this.props.feedFilterRules.new.sort ||
                                      this.props.dataUser.sort_preference ||
                                      "relevant"
                                  ].icon}
                              <Name>
                                {this.props.isFlyer
                                  ? sortOptions[
                                      this.props.dataFlyersSettings.sort
                                    ].displayName
                                  : sortOptions[
                                      queryStringToObject(
                                        this.props.router.location.search
                                      ).sort ||
                                        this.props.feedFilterRules.new.sort ||
                                        this.props.dataUser.sort_preference ||
                                        "relevant"
                                    ].displayName}
                              </Name>
                            </Container>
                          ) : null}
                          <Container wide onClick={this.openFeedLayoutSettings}>
                            <FaGripHorizontal size="1em" />
                            <Name>{`Layout: ${
                              this.props.dataUser.cards_in_layout || 4
                            } cards`}</Name>
                          </Container>
                        </Mask>
                      </DropdownOptions>
                    ) : null}
                  </Container>
                }
              </Group>
            }
          />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataFeed: state.dataFeed,
  dataFlyersSettings: state.dataFlyersSettings,
  dataLandingPageAbout: state.dataLandingPageAbout,
  dataUser: state.dataUser,
  feedFilterRules: state.feedFilterRules,
  feedMultipleFlyerSelect: state.feedMultipleFlyerSelect,
  feedMultiplePhotoSelect: state.feedMultiplePhotoSelect,
  router: state.router,
  hideOrientation: state.hideOrientation,
  showFeedMoreMenu: state.showFeedMoreMenu,
  showHeader: state.showHeader,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedSettings);
