import { emptyObject } from "constants/variables";
import { createSelector } from "@reduxjs/toolkit";
import { EMPTY_QUESTION_CODE } from "components/question-code/QuestionCode.config";
export var selectQuestionCodeIds = function (state) { var _a; return (_a = state.adminData.questionCodes) === null || _a === void 0 ? void 0 : _a.all_ids; };
export var selectQuestionCodes = function (state) { var _a; return (_a = state.adminData.questionCodes) === null || _a === void 0 ? void 0 : _a.by_id; };
export var selectQuestionCodeById = function (state, questionId) {
    var _a, _b;
    return questionId
        ? (_b = (_a = selectQuestionCodes(state)) === null || _a === void 0 ? void 0 : _a[questionId]) !== null && _b !== void 0 ? _b : emptyObject
        : EMPTY_QUESTION_CODE;
};
export var selectQuestionCodeMetadata = function (state) { return state.adminData.questionCodeMetadata; };
export var selectQuestionCodeNames = createSelector([selectQuestionCodes], function (questionCodes) {
    return Object.values(questionCodes || {}).map(function (question) { return question.name.toUpperCase(); });
});
export var selectIsValidName = createSelector([
    selectQuestionCodeNames,
    function (state, name) { return name.toUpperCase(); },
    function (state, name, isNew) { return isNew; }
], function (currentNames, name, isNew) {
    return !isNew || !!name.trim() && !currentNames.includes(name.trim());
});
export var selectQuestionCodesByName = createSelector([selectQuestionCodes, selectQuestionCodeIds, function (state, searchQuery) { return searchQuery.toUpperCase(); }], function (questionCodes, allIds, query) {
    if (query) {
        var matches = Object.values(questionCodes || {}).filter(function (question) { return question.name.toUpperCase().startsWith(query); });
        if (matches.length !== allIds.length) {
            return matches
                .sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true, sensitivity: 'base'
                });
            })
                .map(function (match) { return match.id; });
        }
    }
    return allIds; // Return array from state to prevent re-render
});
