import AuthOptions from "components/auth-options/index";
import styled, { withTheme } from "styled-components";
import Logo from "components/logo/index";
import { PureComponent } from "react";
import { media } from "constants/responsive/index";
import { FaTimes } from "react-icons/fa";
import { awsPrefix, clearbitUrl } from "constants/variables/index";
import { connect } from "react-redux";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 22vh;
  background: ${(props) => props.theme.grey6};
  height: 100%;
  ${media.tablet`
    padding-top: 10vh;
    height: 100%;
    background: ${(props) => props.theme.white};
  `};
  ${media.mobile`
    padding-top: 3vh;
    height: 100%;
    background: ${(props) => props.theme.white};
  `};
`;
const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  padding: 0 1em;
  align-items: flex-start;
  ${media.tablet`
    flex-wrap: wrap;
    justify-content: center;
  `}
  ${media.mobile`
    flex-wrap: wrap;
    justify-content: center;
  `};
`;
const NoFlex = styled.div`
  display: flex;
  align-items: center;
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3em;
  align-items: center;
  max-width: 50%;
  padding: 0 2em;
  ${media.tablet`
    max-width: 100%;
    justify-content: center;
  `};
  ${media.mobile`
    max-width: 100%;
    justify-content: center;
    padding: 0;
  `};
`;
const H2 = styled.h2`
  color: ${(props) => props.theme.grey1};
  margin: 0;
  animation: fadeIn 0.5s;
  padding-left: 8px;
  width: 100%;
  ${media.mobile`
    display: none;
  `};
  ${media.tablet`
    text-align: center;
    width: 100%;
    padding-left: 0;
    margin: 0 1em;
  `};
`;
const P = styled.p`
  padding: 0 8px;
  max-width: 24em;
  color: ${(props) => props.theme.grey2};
  ${media.mobile`
    text-align: center;
  `};
`;
const ShadowContainer = styled.div`
  padding: 2em;
  max-width: 29em;
  margin: 1em;
  animation: slide-from-bottom 0.5s;
  border-radius: 1em;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.notSubtleBoxShadow};
  ${media.mobile`
    box-shadow: none;
    margin: 0;
    padding: 0.5em;
    width: 100%;
  `};
  ${media.tablet`
    box-shadow: none;
    padding: 0.5em;
    width: 100%;
  `};
`;

const UserLogo = styled.img`
  border-radius: 0.75em;
  height: 3em;
  max-width: 11em;
  animation: fadeIn 1s;
  margin-left: 0.25em;
  padding: 0.5em;
  background: rgba(255, 255, 255, 0.6);
`;

const styles = {
  userLogoX: {
    animation: "fadeIn 1s",
    margin: "0 1em",
  },
};

class DefaultLogin extends PureComponent {
  constructor(props) {
    super(props);
    this.onError = this.onError.bind(this);
  }

  onError(e) {
    this.props.setData({ type: "ERROR_LOADING_COMPANY_IMG" });
  }
  render() {
    const companyName = this.props.serverData && this.props.serverData.name;
    const tld =
      this.props.loginData &&
      this.props.loginData.login_tld &&
      this.props.loginData.login_tld.active
        ? this.props.loginData.login_tld.tld
        : null;
    return (
      <Wrapper>
        <InnerWrapper>
          <Flex>
            <NoFlex>
              <Logo width={companyName || tld ? "10em" : "16em"} height="7em" />
              {this.props.loginData &&
              this.props.loginData.error ? null : companyName || tld ? (
                <>
                  <FaTimes
                    size="1em"
                    color={this.props.theme.grey1}
                    style={styles.userLogoX}
                  />
                  <UserLogo
                    onError={this.onError}
                    src={
                      tld
                        ? `${clearbitUrl}${tld}`
                        : `${awsPrefix}companies/${this.props.serverData.companyImg}`
                    }
                  />
                </>
              ) : null}
            </NoFlex>
            {companyName || tld ? (
              <>
                {companyName ? (
                  <H2>{this.props.serverData.name}</H2>
                ) : (
                  <H2>Join your team on Shelfgram</H2>
                )}
                <P>
                  Your colleagues are already finding retail insights faster and
                  easier than ever before.
                </P>
              </>
            ) : (
              <h2>The virtual store walk platform.</h2>
            )}
          </Flex>
          <ShadowContainer>
            <AuthOptions />
          </ShadowContainer>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  loginData: state.loginData,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLogin)
);
