import React, { useEffect } from "react";
import ErrorMessage from "components/auth-options/error-message";
import { useAppSelector } from "@redux/hooks";
import { useApiCall } from "hooks/useApiCall";
import NoAccess from "components/no-access";
import Loader from "components/loader";
export var AccessHoc = function (_a) {
    var children = _a.children, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var planId = useAppSelector(function (state) { var _a; return (_a = state.dataUser) === null || _a === void 0 ? void 0 : _a.plan_id; });
    var landingPageId = useAppSelector(function (state) { var _a; return (_a = state.dataLandingPageAbout) === null || _a === void 0 ? void 0 : _a.id; });
    var hasAccess = useAppSelector(function (state) { var _a; return !!((_a = state.dataLandingPageAbout) === null || _a === void 0 ? void 0 : _a.access); });
    var checkedAccess = useAppSelector(function (state) { var _a; return (_a = state.dataLandingPageAbout) === null || _a === void 0 ? void 0 : _a.access_check; });
    var _c = useApiCall("USER_CHECK_COLLECTION_ACCESS"), apiCall = _c.apiCall, isError = _c.isError;
    useEffect(function () {
        if (!disabled && landingPageId && !checkedAccess) {
            apiCall({
                method: "POST",
                component: "USER_CHECK_COLLECTION_ACCESS",
                route: "collection/id/".concat(landingPageId, "/access"),
            });
        }
    }, [disabled, landingPageId, checkedAccess]);
    return disabled || hasAccess ? (children) : planId === 1 || checkedAccess && !hasAccess ? (React.createElement(NoAccess, null)) : isError ? (React.createElement(ErrorMessage, { error: "An error occurred, please try again" })) : (React.createElement(Loader, null));
};
