import { PureComponent } from "react";

import { FaUserLock } from "react-icons/fa";
import { H3 } from "constants/styled-components/index";
import { connect } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  animation: fadeIn 1s;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3em;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  svg {
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.grey2};
    padding: 1em;
  }
  &:hover {
    svg {
      fill: ${(props) => props.theme.grey3};
    }
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class NoAccess extends PureComponent {
  constructor(props) {
    super(props);
    this.showUpgrade = this.showUpgrade.bind(this);
  }

  showUpgrade() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: "UPGRADE_FLASH",
    });
  }

  render() {
    return (
      <Wrapper onClick={this.showUpgrade}>
        <Container>
          <FaUserLock size="3em" />
        </Container>
        <Container>
          <H3>Sorry, you don't have access to this page</H3>
        </Container>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default connect(null, mapDispatchToProps)(NoAccess);
