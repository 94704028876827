import { PureComponent } from "react";
import { awsPrefix } from "constants/variables";
import { avatarFromName } from "constants/functions";
import Desktop from "constants/responsive/desktop/index";
import { Link } from "react-router-dom";
import moment from "moment";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 2.5em;
  width: 100%;
  align-items: center;
  background: ${(props) => props.theme.white};
  position: absolute;
  top: 0;
  z-index: 1;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.25em 0.35em 0.25em 0.25em;
  box-sizing: border-box;
`;
const Bullet = styled.span`
  padding: 0 0.4em;
`;
const Span = styled.span`
  transition: ${(props) => props.theme.fastTransition};
  font-size: ${(props) => (props.small ? "0.8em" : "1em")};
  font-weight: bold;
  user-select: none;
  color: ${(props) => props.theme.grey2};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.grey1};
  }
`;
const Text = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  color: ${(props) => props.theme.grey2};
  font-size: 0.7em;
`;
const Img = styled.div`
  height: 100%;
  width: 2.25em;
  display: flex;
  border-radius: 3px 0px 0px 0px;
  background-position: center;
  background-size: 95%;
  background-color: white;
  filter: opacity(0.85);
  background-repeat: no-repeat;
`;
const ItemLink = styled(Link)`
  text-overflow: ellipsis;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  color: ${(props) => props.theme.grey2} !important;
  &:hover {
    color: ${(props) => props.theme.grey1} !important;
  }
`;
const SelectCircleContainer = styled.div`
  transition: ${(props) => props.theme.fastTransition};
  position: absolute;
  z-index: 1;
  right: 0.5em;
  display: flex;
  border-radius: 50%;
  background: ${(props) => props.theme.white};
  padding: 2px;
  cursor: pointer;
  box-shadow: ${(props) =>
    `0.5em 0.25em 0.5em ${props.theme.white},-0.5em 0.25em 0.5em ${props.theme.white}`};
  svg {
    transition: ${(props) => props.theme.fastTransition};
    fill: ${(props) =>
      props.isSelected ? props.theme.midBlue : props.theme.grey4};
    width: 1.5em;
    height: 1.5em;
  }
  svg:focus,
  svg:active {
    fill: ${(props) => props.theme.midBlue} !important;
    transform: scale(1.1);
  }
  &:hover {
    svg {
      fill: ${(props) =>
        props.isSelected ? props.theme.deepBlue : props.theme.midBlue};
    }
  }
`;
const SubText = styled.span``;
const To = styled.span`
  padding: 0 0.35em;
`;
const CountryLink = styled(Link)`
  white-space: nowrap;
`;
const SpecificTime = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.grey2};
  background: ${(props) => props.theme.white};
  font-size: 0.7em;
  top: 0;
  right: 0.25em;
  position: absolute;
  padding: 0.5em;
  width: auto;
  border-radius: 2em;
`;

const styles = {
  timeMinWidth: {
    minWidth: "fit-content",
    display: "flex",
    width: "auto",
  },
  retailer: {
    display: "flex",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  profilePicLink: {
    height: "100%",
    padding: "0.25em",
    boxSizing: "border-box",
  },
};

export default class Header extends PureComponent {
  render() {
    const showStoreNumber =
      this.props.showStoreNumber && this.props.data.store_num;
    const showCity =
      (this.props.data.city_id && this.props.data.city_id !== "j7KqU3lO") ||
      (this.props.data.city_slug &&
        this.props.data.city_slug !== "j7KqU3lO" &&
        this.props.showCity);
    const showState =
      (this.props.data.state_id && this.props.data.state_id !== "XHWrjddR") ||
      (this.props.data.state_slug &&
        this.props.data.state_slug !== "XHWrjddR" &&
        this.props.showState);
    const showCountry =
      (this.props.data.country_id &&
        this.props.data.country_id !== "5e55eYqo") ||
      (this.props.data.country_slug &&
        this.props.data.country_slug !== "5e55eYqo" &&
        this.props.showCountry);
    return (
      <Wrapper>
        {this.props.showSpecificTime && (
          <SpecificTime style={styles.specificTime}>
            {moment(this.props.data.date_taken).format("h:mm a")}
          </SpecificTime>
        )}
        {this.props.selectEnabled ? (
          <SelectCircleContainer
            className="hoverCheckCircle"
            isSelected={this.props.isSelected}
            onClick={this.props.handleSelectMultiple}
          >
            {this.props.isSelected ? <FaCheckCircle /> : <FaRegCircle />}
          </SelectCircleContainer>
        ) : null}
        <Link
          to={`/lp/company/${
            this.props.data.retailer_slug || this.props.data.retailer_id
          }/${this.props.isFlyer ? "circulars" : "feed"}`}
          target={this.props.embedded ? "_blank" : null}
          style={styles.profilePicLink}
        >
          <Img
            style={{
              backgroundImage: this.props.data.retailer_img
                ? `url("${awsPrefix}companies/${this.props.data.retailer_img}")`
                : `url("${avatarFromName(this.props.data.retailer)}")`,
            }}
          />
        </Link>
        <Container>
          <Link
            to={`/lp/company/${
              this.props.data.retailer_slug || this.props.data.retailer_id
            }/${this.props.isFlyer ? "flyers" : "feed"}`}
            target={this.props.embedded ? "_blank" : null}
            style={styles.retailer}
          >
            <Span
              small={
                this.props.data.retailer && this.props.data.retailer.length > 24
              }
            >
              {this.props.data.retailer}
            </Span>
          </Link>
          <Text>
            {this.props.isFlyer ? null : this.props.data &&
              (this.props.data.city_slug ||
                this.props.data.city_id ||
                this.props.data.country_slug ||
                this.props.data.country_id) ? (
              <>
                {showStoreNumber && (
                  <ItemLink
                    to={`/lp/location/${
                      this.props.data.location_slug ||
                      this.props.data.location_id
                    }/feed`}
                  >
                    {`#${this.props.data.store_num}`}
                  </ItemLink>
                )}
                {showCity && (
                  <ItemLink
                    to={`/lp/city/${this.props.data.city_slug}/feed`}
                    target={this.props.embedded ? "_blank" : null}
                  >
                    {`${
                      this.props.showStoreNumber && this.props.data.store_num
                        ? ", "
                        : ""
                    }${this.props.data.city}`}
                  </ItemLink>
                )}
                {showState && (
                  <ItemLink
                    to={`/lp/state/${
                      this.props.data.state_slug || this.props.data.state_id
                    }/feed`}
                    target={this.props.embedded ? "_blank" : null}
                  >
                    {`${showStoreNumber || showCity ? ", " : ""}${
                      showCity &&
                      showCountry &&
                      this.props.data.state.includes(" ") &&
                      this.props.data.state.length > 10
                        ? this.props.data.state
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                          this.props.data.state
                            .split(" ")
                            [
                              this.props.data.state.split(" ").length - 1
                            ].charAt(0)
                            .toUpperCase()
                        : this.props.data.state
                    }`}
                  </ItemLink>
                )}
                {showCountry ? (
                  <CountryLink
                    to={`/lp/country/${
                      this.props.data.country_slug || this.props.data.country_id
                    }/feed`}
                    target={this.props.embedded ? "_blank" : null}
                  >
                    {`${showState || showCity || showStoreNumber ? ", " : ""}${
                      showCity &&
                      showState &&
                      this.props.data.city.length +
                        this.props.data.state.length >
                        20
                        ? this.props.data.country_acronym
                        : this.props.data.city &&
                          this.props.data.country.length +
                            this.props.data.city.length >
                            25
                        ? this.props.data.country_acronym
                        : this.props.data.country
                    }`}
                  </CountryLink>
                ) : null}
              </>
            ) : null}
            {this.props.data.start_date && this.props.data.end_date ? (
              <>
                {moment(this.props.data.start_date).format("MMM D, YYYY")}
                <To>to</To>
                {moment(this.props.data.end_date).format("MMM D, YYYY")}
              </>
            ) : null}
            {!this.props.isFlyer &&
            (showStoreNumber || showCity || showState || showCountry) ? (
              <Bullet>•</Bullet>
            ) : null}
            {this.props.isFlyer &&
            !this.props.hidePageNum &&
            this.props.data &&
            this.props.data.page_num ? (
              <Desktop
                content={
                  <>
                    <Bullet>•</Bullet>
                    <SubText>{`Page ${this.props.data.page_num}`}</SubText>
                  </>
                }
              />
            ) : null}
            {this.props.data && this.props.data.date_taken ? (
              <Tooltip
                unmountHTMLWhenHide
                html={
                  <TooltipText>
                    {this.props.showSpecificDate
                      ? moment.utc(this.props.data.date_taken).fromNow()
                      : moment
                          .utc(this.props.data.date_taken)
                          .format("MMMM D, YYYY")}
                  </TooltipText>
                }
                position="top"
                style={styles.timeMinWidth}
              >
                {!this.props.showSpecificDate
                  ? moment.utc(this.props.data.date_taken).fromNow()
                  : moment
                      .utc(this.props.data.date_taken)
                      .format("MMM D, YYYY")}
              </Tooltip>
            ) : null}
          </Text>
        </Container>
      </Wrapper>
    );
  }
}
