var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
export var StyledAdmin = {
    Wrapper: styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        text-align: center;\n        padding: 1em 5em;\n    "], ["\n        text-align: center;\n        padding: 1em 5em;\n    "]))),
    FlexContainer: styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        background: ", ";\n        border-radius: 1em;\n        padding: 1em;\n        width: 100%;\n        display: flex;\n        flex-flow: wrap;\n        align-items: center;\n        justify-content: center;\n    "], ["\n        background: ", ";\n        border-radius: 1em;\n        padding: 1em;\n        width: 100%;\n        display: flex;\n        flex-flow: wrap;\n        align-items: center;\n        justify-content: center;\n    "])), function (_a) {
        var $backgroundColor = _a.$backgroundColor;
        return $backgroundColor;
    }),
    Divider: styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        padding: 1em;\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    "], ["\n        padding: 1em;\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    "]))),
    Description: styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        color: ", ";\n        margin-bottom: 1em;\n        padding: 0;\n        white-space: pre-line;\n    "], ["\n        color: ", ";\n        margin-bottom: 1em;\n        padding: 0;\n        white-space: pre-line;\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.grey2;
    }),
    Label: styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        color: ", ";\n        font-weight: bold;\n        padding: 0 1.5em;\n    "], ["\n        color: ", ";\n        font-weight: bold;\n        padding: 0 1.5em;\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.grey2;
    }),
    Input: styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        margin: 0.75em;\n    "], ["\n        margin: 0.75em;\n    "]))),
    Checkmark: styled(FaCheckCircle)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        margin: 5em;\n        color: ", "\n    "], ["\n        margin: 5em;\n        color: ", "\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.midGreen;
    }),
    Error: styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n        margin-top: 1rem;\n        width: 100%;\n    "], ["\n        margin-top: 1rem;\n        width: 100%;\n    "]))),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
