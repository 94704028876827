var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo, useState } from 'react';
import Pill from "components/pill";
import SimplePill from "components/simple-pill";
import AltCTA from "components/buttons/alternate";
import MainCTA from "components/buttons/main";
import Selector from "components/selector";
import TextBox from "components/text-box";
import Loader from "components/loader";
import { useTheme } from "styled-components";
import { useApiCall } from "hooks/useApiCall";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { getImageSource } from "shared/helpers/image";
import { selectIsValidName, selectQuestionCodeById, selectQuestionCodeMetadata } from "@redux/selectors/admin/questionCodeSelectors";
import { MetadataSelectors } from "components/question-code/MetadataSelectors/MetadataSelectors";
import { FA_PRIVATE_SPACE, questionCodeLinkRows, questionCodeMetadataRows } from "./QuestionCode.config";
import { FaPen, FaSave, FaTrash } from "react-icons/fa";
import { FaFileCirclePlus } from "react-icons/fa6";
import { asyncSelectors } from "constants/variables";
import { FullWidth, Img } from "constants/styled-components";
import { StyledQuestionCode as Styled } from "./QuestionCode.styled";
export var QuestionCode = function (_a) {
    var questionId = _a.questionId, measure = _a.measure;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var isNewQuestionCode = !questionId;
    var _b = useState(''), validationError = _b[0], setValidationError = _b[1];
    var _c = useState(isNewQuestionCode), isEditing = _c[0], setIsEditing = _c[1];
    var _d = useState(isNewQuestionCode), hasUnsavedChanges = _d[0], setHasUnsavedChanges = _d[1];
    var metadata = useAppSelector(selectQuestionCodeMetadata);
    var questionCode = useAppSelector(function (state) { return selectQuestionCodeById(state, questionId); });
    var _e = useState(__assign({}, questionCode)), localQuestionCode = _e[0], setLocalQuestionCode = _e[1];
    var isValidName = useAppSelector(function (state) { return selectIsValidName(state, localQuestionCode.name, isNewQuestionCode); });
    var apiCallComponent = isNewQuestionCode ? "CREATE_QUESTION_CODE" : "UPDATE_QUESTION_CODE_".concat(questionId);
    var _f = useApiCall(apiCallComponent), apiCall = _f.apiCall, resetApiCall = _f.resetApiCall, isSaving = _f.isFetching, isErrorSaving = _f.isError, isSaved = _f.isSuccess;
    var dataProduct = useMemo(function () { var _a; return (_a = metadata.data_products[localQuestionCode.data_product_id]) === null || _a === void 0 ? void 0 : _a.name; }, [metadata.data_products, localQuestionCode.data_product_id]);
    var canSave = useMemo(function () { return !validationError && !!localQuestionCode.name.trim() && !!dataProduct && !!localQuestionCode.photo_source_id; }, [validationError, dataProduct, localQuestionCode]);
    useEffect(function () {
        if (isSaved) {
            if (isNewQuestionCode) {
                dispatch({ type: "HIDE_MODAL" });
            }
            else {
                dispatch({ type: "UPDATE_QUESTION_CODE", data: localQuestionCode });
                setHasUnsavedChanges(false);
                setIsEditing(false);
            }
        }
    }, [isSaved]);
    useEffect(function () {
        if (!isValidName && localQuestionCode.name.trim()) {
            setValidationError("A question code with the name \"".concat(localQuestionCode.name, "\" already exists. Please choose a different name."));
        }
        else if (dataProduct === "Private" && localQuestionCode.private_collections.length !== 1) {
            setValidationError("Question codes with the \"Private\" type must be linked to exactly one private space");
        }
        else {
            setValidationError('');
        }
        if (isErrorSaving)
            resetApiCall();
    }, [isEditing, isValidName, dataProduct, localQuestionCode]);
    useEffect(function () {
        var private_collections = localQuestionCode.private_collections;
        var faSpaceId = FA_PRIVATE_SPACE.id;
        if (dataProduct === "Syndicated" && private_collections.length) {
            if (!private_collections.find(function (pc) { return pc.id === faSpaceId; })) {
                handleAddLink("private_collections", FA_PRIVATE_SPACE);
            }
            else if (private_collections.length === 1) {
                handleRemoveLink("private_collections", faSpaceId);
            }
        }
    }, [dataProduct, localQuestionCode.private_collections]);
    useEffect(function () {
        if (typeof measure === 'function')
            measure(); // Remeasure whenever component size may change
    }, [isEditing, localQuestionCode, validationError, isSaving, isErrorSaving]);
    var handleChangeMandatoryField = function (key, value) {
        setLocalQuestionCode(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
        });
        setHasUnsavedChanges(true);
    };
    var handleAddLink = function (linkType, data) {
        var isSingleSelector = linkType === "private_collections" && dataProduct === "Private";
        var _a = Array.isArray(data) ? data[0] : data, id = _a.id, name = _a.value, img = _a.img, source = _a.source;
        if (!localQuestionCode[linkType].find(function (link) { return link.id === id; })) {
            setLocalQuestionCode(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[linkType] = (isSingleSelector ? [] : prev[linkType]).concat({ id: id, name: name, img: img, source: source }), _a)));
            });
            setHasUnsavedChanges(true);
        }
    };
    var handleRemoveLink = function (linkType, linkId) {
        setLocalQuestionCode(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[linkType] = prev[linkType].filter(function (link) { return link.id !== linkId; }), _a)));
        });
        setHasUnsavedChanges(true);
    };
    var handleSave = function () {
        if (!hasUnsavedChanges) {
            setIsEditing(false);
            return;
        }
        var id = localQuestionCode.id, data = __rest(localQuestionCode, ["id"]);
        if (isNewQuestionCode) {
            dispatch({
                type: "CREATE_QUESTION_CODE",
                data: localQuestionCode
            });
        }
        apiCall({
            method: "POST",
            component: apiCallComponent,
            route: "admin/question_code/update",
            body: __assign(__assign({}, data), { question_id: id, name: data.name.trim(), tags: data.tags.map(function (x) { return x.id; }), categories: data.categories.map(function (x) { return x.id; }), collections: data.collections.map(function (x) { return x.id; }), private_collections: dataProduct === "Public" ? [] : data.private_collections.map(function (x) { return x.id; }) })
        });
    };
    var handleDelete = function () {
        if (!isNewQuestionCode) {
            dispatch({
                type: "SHOW_MODAL",
                data: "DELETE_QUESTION_CODE",
                extraData: questionId
            });
        }
    };
    var handleCancel = function () {
        if (isNewQuestionCode) {
            dispatch({ type: "HIDE_MODAL", data: "CANCEL_NEW_QUESTION_CODE" });
        }
        else {
            setLocalQuestionCode(__assign({}, questionCode));
            if (isErrorSaving)
                resetApiCall();
            setHasUnsavedChanges(false);
            setIsEditing(false);
        }
    };
    return questionCode && metadata && (React.createElement(Styled.Wrapper, null,
        React.createElement(Styled.Container, { "$isNewQuestionCode": isNewQuestionCode },
            React.createElement(Styled.Header, null,
                React.createElement(Styled.TitleContainer, null,
                    isNewQuestionCode ? (React.createElement(Styled.TextArea, { value: localQuestionCode.name, placeholder: "Enter the question code...", "$isDuplicate": !!localQuestionCode.name.trim() && !isValidName, onChange: function (e) { return handleChangeMandatoryField("name", e.target.value.toUpperCase()); } })) : (React.createElement(Styled.H2, null, localQuestionCode.name)),
                    validationError && (React.createElement(Styled.Error, null, validationError))),
                !isEditing && (React.createElement(Styled.MetadataPills, null, questionCodeMetadataRows.map(function (_a) {
                    var source = _a.source, field = _a.field;
                    return (React.createElement(SimplePill, { highlighted: true, key: source, name: metadata[source][questionCode[field]].name }));
                }))),
                React.createElement(Styled.ButtonsContainer, { "$isNewQuestionCode": isNewQuestionCode }, isEditing ? (React.createElement(React.Fragment, null,
                    React.createElement(AltCTA, { text: "Cancel", clickAction: handleCancel }),
                    React.createElement(MainCTA, { text: isNewQuestionCode ? "Create" : "Save", background: isNewQuestionCode && theme.midGreen, backgroundHover: isNewQuestionCode && theme.deepGreen, icon: isNewQuestionCode
                            ? React.createElement(FaFileCirclePlus, { className: "override-fill", fill: "white", size: "0.9em" })
                            : React.createElement(FaSave, { className: "override-fill", fill: "white", size: "0.9em" }), clickAction: handleSave, disabled: !canSave }))) : (React.createElement(React.Fragment, null,
                    React.createElement(MainCTA, { text: "Delete", background: theme.midRed, backgroundHover: theme.deepRed, icon: React.createElement(FaTrash, { className: "override-fill", fill: "white", size: "0.8em" }), clickAction: handleDelete }),
                    React.createElement(MainCTA, { text: "Edit", icon: React.createElement(FaPen, { className: "override-fill", fill: "white", size: "0.8em" }), clickAction: function () { return setIsEditing(true); } })))),
                isSaving ? (React.createElement(Loader, { size: "1.5em" })) : isEditing && isErrorSaving && (React.createElement(Styled.Error, null, "An error occurred while saving. Please try again."))),
            isEditing && (React.createElement(MetadataSelectors, { localQuestionCode: localQuestionCode, handleChange: handleChangeMandatoryField })),
            questionCodeLinkRows
                .filter(function (row) { return dataProduct !== "Public" || row.linkType !== "private_collections"; })
                .map(function (_a) {
                var linkType = _a.linkType, title = _a.title, route = _a.route;
                return (React.createElement(TextBox, { showOverflow: true, key: linkType, title: title, minWidth: "100%", text: React.createElement(React.Fragment, null,
                        React.createElement(Styled.LinkPills, null, localQuestionCode[linkType].map(function (_a) {
                            var name = _a.name, img = _a.img, source = _a.source, id = _a.id;
                            return (isEditing ? (React.createElement(SimplePill, { key: id, name: name, highlighted: true, deletePending: true, icon: React.createElement(Img, { src: getImageSource({ source: source, img: img, name: name }) }), clickAction: function () { return handleRemoveLink(linkType, id); } })) : (React.createElement(Pill, { key: id, name: name, img: img, id: id, route: route, source: source, tab: "about", embedded: true })));
                        })),
                        isEditing && (React.createElement(FullWidth, null,
                            React.createElement(Selector, { name: linkType, selectorType: linkType, async: asyncSelectors.includes(linkType), placeholder: "Select ".concat(title.toLowerCase(), "..."), clickAction: function (e) { return handleAddLink(linkType, e.data); }, hideSelectorType: asyncSelectors.includes(linkType), minWidth: "100%", tagging: true, portal: true })))) }));
            }))));
};
