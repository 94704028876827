import { PureComponent } from "react";

import { connect } from "react-redux";
import styled from "styled-components";

const Container = styled.span`
  color: ${(props) => props.theme.veryLightGrey};
  padding: 0.75em 1em;
  cursor: pointer;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "2em"};
  transition: ${(props) => props.theme.fastTransition};
  font-weight: bold;
  margin: 0.25em;
  background: ${(props) => props.theme.midBlue};
  &:hover {
    background: ${(props) => props.theme.midBlue};
    color: ${(props) => props.theme.white};
  }
  &:active {
    transform: scale(0.97);
  }
`;

class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.setData({
      type: "SHOW_MODAL",
      data: this.props.deactivated ? "LOGIN_DISABLED" : "LOGIN",
      extraData: {
        company: this.props.company,
        redirect: this.props.redirect
          ? this.props.redirect
          : this.props.router.location.pathname,
        signUp: true,
      },
    });
  }

  render() {
    return (
      <Container
        onClick={this.handleClick}
        borderRadius={this.props.borderRadius}
      >
        {this.props.text ? this.props.text : "Sign Up"}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
