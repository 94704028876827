import React, { PureComponent, Suspense, lazy } from "react";
import { maxSizeStyle, maxUploadSize } from "constants/variables/index";

import Loader from "components/loader/index";
import Dropzone from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { connect } from "react-redux";
import styled from "styled-components";

const UploadProgress = lazy(() =>
  import(
    /* webpackChunkName: "dynamic-UploadProgress" */ "components/upload-progress/index"
  )
);

const DropzonePreview = styled.div`
  background: rgba(250, 250, 250, 0.975);
  width: 100%;
  height: 100%;
  z-index: 999999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  position: relative;
  transition: ${(props) => props.theme.defaultTransition};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em;
`;
const IconContainer = styled.div`
  position: absolute;
  top: 0;
  margin-top: -2.5em;
  padding: 1em;
  border-radius: 100%;
  background: ${(props) => props.theme.grey2};
  animation: pulse 2s infinite;
  display: flex;
  svg {
    fill: white;
  }
`;
const H1 = styled.h1`
  color: ${(props) => props.theme.grey2};
`;

export let dropzoneRef = React.createRef();

const inputProps = {
  id: "shelfgram-upload-dropzone",
};

class Upload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      retailer: null,
    };
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
  }

  blobToFile(theBlob, fileName, lastModifiedDate) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = lastModifiedDate;
    theBlob.name = fileName;
    theBlob.preview = URL.createObjectURL(theBlob);
    return theBlob;
  }

  onDrop(uploads) {
    if (this.props.dataUser && this.props.dataUser.plan_id === 1) {
      return;
    }
    if (this.props.router.location) {
      if (
        this.props.router.location.pathname.split("/").pop() == "settings" &&
        !this.props.router.location.pathname.includes("user")
      ) {
        this.props.setData({ type: "HIDE_DROPZONE" });
        return;
      } else if (
        this.props.router.location.pathname.includes("files") &&
        this.props.router.location.pathname.includes("private_collection")
      ) {
        this.props.setData({ type: "HIDE_DROPZONE" });
        this.props.setData({
          type: "PENDING_UPLOAD_DROP_FILES",
          data: uploads,
        });
        this.props.setData({ type: "SHOW_UPLOAD_PROGRESS", data: "files" });
        return;
      }
    }
    this.props.setData({ type: "PENDING_UPLOAD_DROP_PHOTOS", data: uploads });
    this.props.setData({ type: "HIDE_DROPZONE" });
    this.props.setData({
      type: "SHOW_MODAL",
      data: "UPLOAD_PHOTOS",
    });
    this.props.setData({
      type: "HIDE_UPLOAD_PROGRESS",
    });
  }

  onDragEnter() {
    if (this.props.router.location) {
      if (
        this.props.router.location.pathname.split("/").pop() == "settings" &&
        !this.props.router.location.pathname.includes("user")
      ) {
        return;
      }
    }
    if (this.props.dataUser && this.props.dataUser.plan_id === 1) {
      return;
    } else if (!this.props.showDropzone) {
      this.props.setData({ type: "SHOW_DROPZONE" });
    }
  }

  onDragLeave() {
    if (this.props.showDropzone) {
      this.props.setData({ type: "HIDE_DROPZONE" });
    }
  }

  onDropRejected(file) {
    this.props.setData({
      type: "HIDE_UPLOAD_PROGRESS",
    });
    const maxSize = Math.max(...file.map((x) => x.size));
    this.props.setData({
      type: "SHOW_MODAL",
      data: "UPLOAD_FAILED",
      extraData:
        maxSize > maxUploadSize
          ? "fileSize"
          : this.props.router &&
            this.props.router.location &&
            this.props.router.location.pathname &&
            this.props.router.location.pathname.includes("files") &&
            this.props.router.location.pathname.includes("private_collection")
          ? "PDF"
          : "image",
    });
  }

  render() {
    return (
      <Dropzone
        ref={(node) => {
          dropzoneRef = node;
        }}
        style={maxSizeStyle}
        accept={
          this.props.router &&
          this.props.router.location &&
          this.props.router.location.pathname &&
          this.props.router.location.pathname.includes("files") &&
          this.props.router.location.pathname.includes("private_collection")
            ? [".pdf"]
            : ["image/*"]
        }
        minSize={0}
        maxSize={maxUploadSize}
        disableClick
        multiple
        inputProps={inputProps}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDropRejected={this.onDropRejected}
        className="Dropzone"
      >
        {this.props.showDropzone ? (
          <DropzonePreview>
            <Container>
              <IconContainer>
                <FaCloudUploadAlt size="3em" />
              </IconContainer>
              <H1>Drop to upload to Shelfgram</H1>
            </Container>
          </DropzonePreview>
        ) : null}
        {this.props.showUploadProgress ? (
          <Suspense fallback={<Loader />}>
            <UploadProgress typeOfUpload={this.props.showUploadProgress} />
          </Suspense>
        ) : null}
        {this.props.children}
      </Dropzone>
    );
  }
}

const mapStateToProps = (state) => ({
  showDropzone: state.showDropzone,
  dataUser: state.dataUser,
  router: state.router,
  showUploadProgress: state.showUploadProgress,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
